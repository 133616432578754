* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
:root{
  --theme-color:#efc80c;
  --white:#FFFFFF;
  --black:#000000;
}
body {
  background: #191919;
  color:var(--white);
  font-family: "PT Mono", monospace;
}

a {
  text-decoration: none;
  color: var(--white);
}

input[type="number"] {
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
}

.star-icon-fill {
  fill: var(--theme-color);
}
.drawer-open-section {
  width: 550px;
}
.smile-box {
  width: 100%;
  position: relative;
  z-index: 0;

  &::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: var(--white);
  }
}

.smile-img {
  background-color: #191919;
  position: absolute;
  /* margin-inline: 30px; */
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: var(--white);
  }
}

.search-filed {
  & input {
    border: 2px solid var(--white) !important;
  }

  &:hover {
    /* border: 2px solid var(--white) !important; */
    outline: none !important;
  }

  .MuiInputBase-root:hover fieldset {
    border: none !important;
  }
}

.textFieldcustomLable {
  color: var(--white) !important;
}

.customInput {
  color: var(--white) !important;
  caret-color: var(--theme-color) !important;
}

.customInput::before {
  border-bottom: 2px solid var(--theme-color) !important;
}

.custom-button {
  border-radius: 0px !important;
  border: 2px solid var(--theme-color) !important;
  color: var(--white) !important;
}

.custom-button:hover {
  border: 2px solid var(--theme-color) !important;
}

.main-heading {
  color: #b0b0b04f;
  font-size: 82.128px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fixed-navbar {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #191919;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: all 0.3s ease-in-out;
}

.navbar {
  padding: 20px 0px;
  position: absolute;
  top: 0;
  z-index: 999999999;
  width: 100%;

  .navbar-section {
    display: flex;
    align-items: center;
    height: 100px;
  }

  .icon-box {
    display: none;
  }

  .nav-list-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .nav-list {
      text-transform: uppercase;
      padding: 10px 20px;
      color: #d9d9d9;
      font-size: 13.66px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      position: relative;
      overflow: hidden;
      display: inline;

      &::before {
        position: absolute;
        display: block;
        content: "";
        bottom: 0;
        left: -100%;
        width: 50% !important;
        height: 1px;
        transition: 0.4s;
        background-color: var(--theme-color);
        margin-inline: auto;
      }
    }

    .active {
      color: #d9d9d9;
      &::before {
        left: 0%;
        right: 0;
        width: 50% !important;
        margin-inline: auto;
      }
    }
  }
}
.sing-up-section {
  .star-icon {
    .star-icon-fill {
      fill: none;
    }
  }
}
.banner-section {
  /* background-image: url(./assets/img/bannerdemo.png); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* min-height: 729px; */
  position: relative;
  z-index: 0;

  .banner-box {
    position: absolute;
    left: 10%;
    top: 22%;

    .logo-banner {
      width: 450px;
      height: 450px;
    }
  }
}

.passe-banner-section {
  /* background-image: url(./assets/img/banner2.png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 120vh;
  position: relative;

  .banner-box {
    width: min(100% - 0px, 40%);
    text-align: center;
    margin: 0px auto;
    padding: 50px 0px;
  }

  .arrow-icon {
    width: 100px;
    height: 100px;
  }

  .right-box-section {
    width: min(100% - 0px, 50%);
    position: absolute;
    top: 46%;
    left: 46%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .left-box-section {
    width: min(100% - 0px, 18%);
    position: absolute;
    bottom: 17%;
    right: 62%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.trending-head-title {
  font-size: 5rem !important;
  color: #b0b0b04f;
}

.product-card {
  height: 340px;
  padding: 5px;
  display: flex;
  align-items: center;
  border-radius: 0px !important;

  .img-box {
    width: min(100% - 0px, 90%);
    height: 300px;
    margin-inline: auto;
    padding: 20px 0px;
    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .card-contain {
    padding: 10px 10px;
    position: relative;
    height: 100%;
    width: 100%;
  }

  .card-details-box {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    padding: 10px;
    bottom: 0;
    width: 100%;
    .main-text a {
      font-size: 17px;
      font-weight: 700;
      text-transform: capitalize;
      color: var(--black);
    }

    .price-box {
      display: flex;
      align-items: last baseline;

      .main-price-text {
        font-size: 22px;
      }

      .sub-rpice {
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }

  .head-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .rating-box {
      background-color: #191919;
      padding: 5px 7px;
      color: var(--white);
      display: flex;
      align-items: center;

      .rating-text {
        font-size: 14px;
        margin-left: 7px;
      }
    }
    .rating-text-box {
      .star-icon-fill {
        fill: var(--white);
      }
    }

    .rating-star {
      .star-icon-fill {
        fill: var(--white);

        &:hover {
          fill: var(--theme-color);
        }
        &:checked {
          fill: var(--theme-color);
        }
      }
    }

    .set-rating-star {
      .star-icon-fill {
        fill: var(--theme-color);

        &:hover {
          fill: var(--white);
        }
        &:checked {
          fill: var(--white);
        }
      }
    }
  }
}

.footer-icon-box {
  padding-left: 70px;
}

.product-zoom-left-setion {
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .ss {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    height: 400px;
    /* background-color: black; */
  }

  .slider-section {
    /* border: 1px solid red; */
    width: 100%;
    height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(231, 231, 231); */
    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left;
    }
  }

  .star-box {
    position: relative;
  }

  .star-box::after {
    content: "";
    height: 100%;
    width: 2px;
    background-color: #888888;
    position: absolute;
    z-index: -1;
    right: 7px;
  }

  .zoom-section img {
    width: 100%;
    /* height: 100% !important; */
  }

  .next {
    top: 380px !important;
    left: 20px !important;
    background-color: var(--dark);
    padding: 20px;
  }

  .next::after {
    rotate: 90deg;
    font-size: 20px !important;
    color: var(--light);
  }

  .prev {
    top: 10px !important;
    left: 20px !important;
    background-color: var(--dark);
    padding: 20px;
  }

  .prev::after {
    rotate: 90deg;
    font-size: 20px !important;
    color: var(--light);
  }
}

.product-details-right-section {
  .size-text {
    margin-right: 5px;
    .text-color {
      cursor: pointer;
      &:hover {
        color: var(--theme-color);
      }
    }
  }
}

.select-address-head-section {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .main-text {
    position: relative;
    z-index: 0;
    width: 300px;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: min(100% - 0px, 280px);
      bottom: -10px;
      margin-top: 10px;
      border-bottom: 1px solid var(--theme-color);
    }
  }

  .add-addres-text {
    position: relative;
    display: flex;
    z-index: 0;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: min(100% - 0px, 280px);
      bottom: -10px;
      margin-top: 10px;
      border-bottom: 1px solid var(--theme-color);
    }
  }
}

.select-address-box {
  margin-top: 20px;
  /* margin-bottom: 20px; */

  .main-title-box {
    display: inline-block;
    font-size: 22px;
    font-weight: 700;
    color: var(--white);
    padding: 10px;
    border-bottom: 1px solid var(--theme-color);

    .select-opption-text {
      font-size: 12px;
      margin-left: 20px;
      color: var(--theme-color);
      font-weight: 400;
    }
  }

  .select-address-here {
    margin-top: 10px;
    padding: 10px;
  }
}

.product-card- {
  border-radius: 0 !important;
  box-shadow: none !important;
  .product-img {
    height: 350px;
    width: min(100% - 0px, 100%);
    margin-inline: "auto";
  }
  .pro-rating-star {
    .star-icon-fill {
      fill: var(--white);
      &:hover {
        fill: var(--theme-color);
      }
    }
  }
}
.pro-rating-star {
  .star-icon-fill {
    fill: var(--white);
    &:hover {
      fill: var(--theme-color);
    }
  }
}
.contact-main-text {
  position: relative;
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 10px;

  &::after {
    content: "";
    position: absolute;
    left: 10px;
    bottom: 0;
    width: 100px;
    height: 1px;
    background-color: var(--theme-color);
  }
}

@media (max-width: 768px) {
  .product-card- {
    /* padding-inline:20px ; */
    border-radius: 0 !important;
    box-shadow: none !important;
    .product-img {
      height: 280px !important;
    }
  }

  .drawer-open-section {
    width: 250px;
  }
  .select-address-head-section .main-text {
    width: 173px;
  }

  .banner-section .banner-box .logo-banner {
    width: 200px;
    height: 200px;
  }

  .footer-icon-box {
    padding-left: 0px;
  }

  .passe-banner-section {
    .banner-box {
      width: min(100% - 0px, 95%);
      text-align: center;
      margin: 0px auto;
      padding: 20px 0px;
      font-size: 12px;
    }

    .arrow-icon {
      width: 50px;
      height: 50px;
    }
  }

  .product-zoom-left-setion {

    .next {
      top: 50px !important;
      left: auto !important;
    }

    .next::after {
      rotate: 0deg;
    }

    .prev {
      top: 50px !important;
      right: auto !important;
    }

    .prev::after {
      rotate: 0deg;
    }

    .slider-section {
      padding: 0;
    }
  }
  .product-zoom-left-setion .swiper-slide {
    height: 70px;
  }
  .trending-head-title {
    font-size: 3rem !important;
  }

  .product-card {
    height: 250px !important;

    .img-box {
      width: min(100% - 0px, 100%) !important;
      height: 230px !important;
      margin-inline: auto;

      & img {
        object-fit: contain;
      }
    }

    .card-contain {
      padding: 10px 10px;
      position: relative;
      height: 100%;
    }

    .card-details-box {
      margin-top: auto;

      .main-text {
        font-size: 15px;
      }

      .price-box {
        .main-price-text {
          font-size: 17px;
        }

        .sub-rpice {
          font-size: 12px;
          margin-left: 10px;
        }
      }
    }

    .head-section {
      margin-bottom: 10px;

      .rating-box {
        padding: 5px 7px;

        .rating-text {
          font-size: 12px;
          margin-left: 7px;
        }
      }
    }
  }
}
/* ================== */
.close-icon {
  cursor: pointer;
}

.selected {
  color: var(--theme-color) !important;
}

@media (max-width: 820px) and (min-width: 820px) {
  .product-zoom-left-setion .swiper-slide {
    height: 70px !important;
  }
  .product-zoom-left-setion .ss {
    height: auto;
  }
}
@media (max-width: 769px) and (min-width: 769px) {
  .product-zoom-left-setion .ss {
    height: auto;
  }
}
@media (max-width: 991px) {
  .product-card {
    height: 298px;

    .img-box {
      width: min(100% - 0px, 90%);
      height: 250px;
    }
  }

  .passe-banner-section {
    min-height: auto;
  }

  .navbar {
    padding: 20px 0px;

    .icon-box {
      display: block;
    }

    .menu-hidden {
      display: none !important;
      transition: all 0.5s ease-in-out;
    }

    .menu-visible {
      width: min(100% - 0px, 95%);
      margin-inline: auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 100px;
      transition: all 0.5s ease-in-out;
    }

    .nav-list-group {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      background-color: #191919;
    }
  }
}

@media (max-width: 1024px) and (min-width: 1024px) {

  .passe-banner-section {
    min-height: 50vh;
  }
  .product-card {
    height: 298px;

    .img-box {
      width: min(100% - 0px, 90%);
      height: auto;
    }
  }
}
@media (max-width: 600px) {
  .product-zoom-left-setion .ss {
    height: auto;
  }
}

@media (min-width: 1400px) {
  .banner-section {
    min-height: 100vh;
  }
  .passe-banner-section {
    min-height: 100vh;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
